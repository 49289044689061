import React, { useState, useEffect, useRef, useCallback } from 'react';
import { scanIcon } from "../components/ui/icons";
import {Button, IconButton, PrimaryButton} from "../components/ui/buttons";
import {ErrorBubble, InfoBubble, SuccessAlert, WarningBubble} from "../components/ui/Alerts";
import {api} from "../service/api";
import {User} from "../service/user";
import Table from "../components/ui/Table";
import toast from "react-hot-toast";

const MIN_BARCODE_LENGTH = 5;

const Acceptance = (callback) => {
    const [acceptance, setAcceptance] = useState(false);
    const [scanMode, setScanMode] = useState(null);
    const [cell, setCell] = useState(null);
    const [alertVisible, setAlertVisible] = useState(false);
    const cellMode = 'cell';
    const orderMode = 'order';
    const [barcode, setBarcode] = useState('');
    const [orders, setOrder] = useState([]);
    const [error, setError] = useState('');
    const [warning, setWarning] = useState('');
    const lastKeyTime = useRef(0);
    const accumulatedInput = useRef('');
    const [pupID, setPupID] = useState('');


    useEffect(() => {
        const initializePupID = async () => {
            try {
                const user = await User.get();
                if (user && user.pickup_point_info && user.pickup_point_info.id) {
                    setPupID(user.pickup_point_info.id);
                    console.log(pupID);
                } else {
                    setError('No pickup point ID found');
                }
            } catch (error) {
                setError(error.message);
            }
        };

        initializePupID();
    }, [pupID]);


    const resetPage = () => {
        setAcceptance(false);
        setScanMode(null);
        setCell(null);
        setOrder([]);
        setBarcode('');
        setError('');
        setWarning('');
    }

    const deleteOrder = (index) => {
        setOrder(orders.filter((_, i) => i !== index));
    }

    const getOrdersIds = useCallback(() => {
        return orders.map(o => o.id);
    }, [orders]);


    const orderExists = useCallback((track_num) => {
        return orders.some(o => o.tracking_number === track_num);
    }, [orders]);

    const getCell = useCallback(async ({barcode}) => {
        try {
            return await api.getCellByBarcode(pupID, barcode);
        } catch (error) {
            throw new Error(error.message);
        }
    }, [pupID]);

    const getOrder = useCallback(async ({barcode}) => {
        try {
            return await api.getOrderForAcceptance(barcode, pupID, cell.id);
        } catch (error) {
            throw new Error(error.message);
        }
    }, [pupID, cell]);


    const handleScannedCode = useCallback(async (barcode) => {
        if (scanMode === cellMode) {
            try {
                const cellData = await getCell({barcode});
                setCell(cellData);
                setScanMode(orderMode);
                setError('');
            } catch (error) {
                setError(error.message);
            }
        } else if (scanMode === orderMode) {
            try {
                if (barcode === cell.barcode) {
                    resetPage();
                    return;
                }
                if (orderExists(barcode)) {
                    setError('Посылка с трек номером ' + barcode + ' уже в таблице');
                    return;
                }

                const orderData = await getOrder({barcode});

                // put orderData into order state
                setOrder(prevOrder => [...prevOrder, orderData.order]);
                if (orderData.warning) {
                    setWarning(orderData.warning);
                } else {
                    setWarning('');
                }

                setError('');
            } catch (error) {
                setError(error.message);
                return;
            }
            setBarcode(barcode);
            setError('');
        }
    }, [scanMode, getCell, getOrder, orderExists, cell]);

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (acceptance) {
                const currentTime = new Date().getTime();
                if (currentTime - lastKeyTime.current > 50) {
                    accumulatedInput.current = '';
                }
                lastKeyTime.current = currentTime;

                if (event.key === 'Enter') {
                    if (accumulatedInput.current.length >= MIN_BARCODE_LENGTH) {
                        handleScannedCode(accumulatedInput.current);
                    }
                    accumulatedInput.current = '';
                } else {
                    accumulatedInput.current += event.key;
                }
            }
        };

        window.addEventListener('keypress', handleKeyPress);

        return () => {
            window.removeEventListener('keypress', handleKeyPress);
        };
    }, [acceptance, handleScannedCode]);



    const startAcceptance = async () => {
        try {
            setAcceptance(true);
            setScanMode(cellMode);
        } catch (error) {
            setError(error.message);
        }
    };

    const acceptOrders = async () => {
        try {
            const ordersIds = getOrdersIds();
            if (ordersIds.length === 0) {
                resetPage();
                return
            }
            await api.acceptOrders(ordersIds, cell.id);
            toast.success('Посылки успешно приняты и готовы к выдаче')
            resetPage();
            // callback();
        } catch (error) {
            setError(error.message);
        }
    }


    return (
        <>
            <div className="flex justify-between flex-nowrap items-center pt-3 pb-2 mb-3 border-b w-full">
                <h1 className="text-2xl font-semibold w-full md:w-auto">Прием</h1>
                <div className="flex mb-2 md:mb-0">
                    <div className="flex space-x-2">
                        {/*buttons*/}
                    </div>
                </div>
            </div>

            <div className="max-w-xs">
                <div className={`${acceptance === true ? 'hidden' : ''}`}>
                    <IconButton icon={scanIcon({color: 'white', width: '24px', height: '24px'})}
                                name="Начать сканирование" onClick={startAcceptance}/>
                </div>
                <div>
                    <div className="flex flex-col gap-1">
                        {acceptance && scanMode === cellMode ? InfoBubble({
                            title: 'Прием начался',
                            text: 'Сканируйте ячейку'
                        }) : null}
                        {acceptance && scanMode === orderMode ? InfoBubble({
                            title: `Прием начался в ячейку № ${cell.number}`,
                            text: 'Сканируйте заказ'
                        }) : null}
                        {warning && (
                            WarningBubble({title: 'Предупреждение', text: warning})
                        )}
                        {
                            error && (
                                ErrorBubble({text: error})
                            )
                        }
                    </div>
                    {barcode && (
                        <p className="mt-4">Последний отсканированный код: {barcode}</p>
                    )}
                </div>
            </div>

            <Table columns={['№','Название', 'Дата создания', 'Трек-номер', 'Вес в гр.', 'Статус']} data={orders}
                   onDelete={deleteOrder}></Table>
            <div className={`${acceptance === false ? 'hidden' : ''}`}>
                <PrimaryButton onClick={acceptOrders} name="Принять"/>
            </div>
        </>
    );
};

export default Acceptance;