import React from "react";

export const IconButton = ({ icon, onClick, name, disabled }) => (
    <button type="button" className="flex items-center gap-2 text-white bg-blue-500 hover:bg-blue-600 font-medium rounded px-4 py-2" onClick={onClick}>
        {icon}
        {name}
    </button>
);

export const Button = ({ onClick, name }) => (
    <button type="button" className="btn btn-sm border border-gray-300 text-gray-700 hover:bg-gray-200 rounded px-3 py-1" onClick={onClick}>
        {name}
    </button>
);

export const PrimaryButton = ({ onClick, name }) => (
    <button
        type="button"
        className="flex items-center gap-2 text-white bg-blue-500 hover:bg-blue-600 font-medium rounded px-4 py-2"
        onClick={onClick}>
        {name}
    </button>
);

export const SecondaryButton = ({ onClick, name }) => (
    <button
        type="button"
        className="flex items-center gap-2 text-blue-500 hover:bg-blue-500 hover:text-white font-medium rounded px-4 py-2"
        onClick={onClick}>
        {name}
    </button>
)
