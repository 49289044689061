import React, { useState } from 'react';
import { useQuery } from "@tanstack/react-query";
import {Button, PrimaryButton, SecondaryButton} from "../components/ui/buttons";
import { buildQueryString, formatDate, getPaginationHeadersFromResponse } from "../service/utils";
import { api } from "../service/api";
import Dropdown from "../components/ui/Dropdown";
import {FilterX} from "lucide-react";
import Search from "../components/ui/Search";

const Warehouse = () => {
    const columns = ['№', 'Наименование', 'Дата создания', 'Трек-номер', 'Вес в гр.', 'Объем', 'Статус', 'ПВЗ', 'Получатель', 'Номер получателя', 'Ячейка'];
    const statuses = ['Статус',"Создан", "Принят", "Отправлен в сортировочный центр", "В сортировочном центре", "Отправлен из сортировочного центра", "Готов к выдаче", "Выдан"]
    const [queryParams, setQueryParams] = useState({
        page: 1,
        status: 0,
        iin: '',
        tracking_num: '',
    });

    // Запрос заказов с использованием React Query
    const {
        data: ordersData,
        isLoading,
        error,
        isError
    } = useQuery({
        queryKey: ['warehouse-orders', queryParams],
        queryFn: async () => {
            const queryString = buildQueryString(queryParams);
            const response = await api.getPupOrders(queryString);
            return {
                orders: response.entity,
                pagination: getPaginationHeadersFromResponse(response)
            };
        },
        // staleTime: 1000 * 60,
    });

    const handlePageChange = (page) => {
        if (page < 1 || page > ordersData?.pagination?.totalPages) return;
        setQueryParams(prev => ({
            ...prev,
            page
        }));
    };

    const handleChangeStatus = (value) => {
        handleFilterChange('status', value)
    }

    const handleSearch = ({category, query}) => {
        if (category === 'ИИН') {
            handleFilterChange('iin', query)
        } else if (category === 'Трек-номер') {
            handleFilterChange('tracking_num', query)
        }
    }

    const handleFilterChange = (key, value) => {
        setQueryParams(prev => ({
            ...prev,
            [key]: value,
            page: 1
        }));
    };

    const handleResetFilters = () => {
        setQueryParams({
            page: 1,
            status: 0,
            tracking_num: '',
            iin:'',
        });
    };

    const renderPaginationItems = () => {
        const items = [];
        const maxVisiblePages = 5;
        let startPage = Math.max(1, ordersData.pagination.currentPage - Math.floor(maxVisiblePages / 2));
        let endPage = Math.min(ordersData.pagination.totalPages, startPage + maxVisiblePages - 1);

        if (endPage - startPage + 1 < maxVisiblePages) {
            startPage = Math.max(1, endPage - maxVisiblePages + 1);
        }

        items.push(
            <button
                key="prev"
                onClick={() => handlePageChange(ordersData.pagination.currentPage - 1)}
                disabled={Number(ordersData.pagination.currentPage) === 1}
                className={`px-3 py-2 leading-tight border border-r-0 border-gray-200 rounded-l
                    ${Number(ordersData.pagination.currentPage) === 1
                    ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                    : 'text-indigo-700 bg-white hover:bg-indigo-500 hover:text-white'}`}
            >
                Предыдущая
            </button>
        );

        for (let i = startPage; i <= endPage; i++) {
            items.push(
                <button
                    key={i}
                    onClick={() => handlePageChange(i)}
                    className={`px-3 py-2 leading-tight border border-r-0 border-gray-200
                        ${ordersData.pagination.currentPage === i
                        ? 'bg-indigo-500 text-white'
                        : 'text-indigo-700 bg-white hover:bg-indigo-500 hover:text-white'}`}
                >
                    {i}
                </button>
            );
        }

        items.push(
            <button
                key="next"
                onClick={() => handlePageChange(Number(ordersData.pagination.currentPage) + 1)}
                disabled={ordersData.pagination.currentPage === ordersData.pagination.totalPages}
                className={`px-3 py-2 leading-tight border border-gray-200 rounded-r
                    ${ordersData.pagination.currentPage === ordersData.pagination.totalPages
                    ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                    : 'text-indigo-700 bg-white hover:bg-indigo-500 hover:text-white'}`}
            >
                Следующая
            </button>
        );

        return items;
    };

    return (
        <>
            <div className="flex justify-between flex-nowrap items-center pt-3 pb-2 mb-3 border-b w-full">
                <h1 className="text-2xl font-semibold w-full md:w-auto">Склад</h1>
                <div className="flex mb-2 md:mb-0">
                    <div className="flex space-x-2">
                        <Button name="Export" disabled={isLoading}/>
                    </div>
                </div>
            </div>

            {/* Индикатор загрузки */}
            {isLoading && (
                <div className="text-sm text-gray-500">
                    Загрузка заказов...
                </div>
            )}

            {/* Обработка ошибок */}
            {isError && (
                <div className="text-sm text-red-500">
                    Ошибка: {error.message}
                </div>
            )}
            <>
                <div className="flex items-center gap-2">
                    <div className="max-w-xs">
                        <Dropdown selectedValue={queryParams.status} options={statuses} onChange={handleChangeStatus}/>
                    </div>
                    <div>
                        <Search
                            onSearch={handleSearch}
                            placeholder={'Введите трек-номер'}
                        />
                    </div>
                    <div className="border-l border-gray-300 h-8"></div>
                    {/* Вертикальная линия */}
                    <div>
                        <button className="bg-white p-2 rounded">
                            <FilterX onClick={handleResetFilters} color={'#3F83F8'}/>
                        </button>
                    </div>
                </div>

                <div className="flex flex-col mt-6">
                    <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                        <div
                            className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 sm:rounded-lg">
                            <table className="min-w-full">
                                <thead>
                                <tr>
                                    {columns.map((column, index) => (
                                        <th key={index}
                                            className="px-6 py-3 bg-gray-50 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                            {column}
                                        </th>
                                    ))}
                                    <th className="px-6 py-3 bg-gray-50 border-b border-gray-200"/>
                                </tr>
                                </thead>
                                {ordersData?.orders && ordersData.orders.length > 0 && (
                                    <tbody className="bg-white text-left text-sm text-gray-800">
                                    {ordersData.orders.map((row, rowIndex) => (
                                        <tr key={rowIndex}>
                                            <td className="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                                                {(ordersData.pagination.currentPage - 1) * ordersData.pagination.pageSize + rowIndex + 1}
                                            </td>
                                            <td className="px-6 py-4 border-b border-gray-200 whitespace-nowrap">{row.name || '-'}</td>
                                            <td className="px-6 py-4 border-b border-gray-200 whitespace-nowrap">{row.created_at ? formatDate(row.created_at) : '-'}</td>
                                            <td className="px-6 py-4 border-b border-gray-200 whitespace-nowrap">{row.tracking_number || '-'}</td>
                                            <td className="px-6 py-4 border-b border-gray-200 whitespace-nowrap">{row.weight || '-'}</td>
                                            <td className="px-6 py-4 border-b border-gray-200 whitespace-nowrap">{row.volume || '-'}</td>
                                            <td className="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                                                <div
                                                    className="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">
                                                    {row.external_status || '-'}
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 border-b border-gray-200 whitespace-nowrap">{row.pickup_point_name || '-'}</td>
                                            <td className="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                                                {(row.customer_last_name || row.customer_first_name)
                                                    ? `${row.customer_last_name} ${row.customer_first_name}`
                                                    : '-'}
                                            </td>
                                            <td className="px-6 py-4 border-b border-gray-200 whitespace-nowrap">{row.customer_phone || '-'}</td>
                                            <td className="px-6 py-4 border-b border-gray-200 whitespace-nowrap">{row.cell_number || '-'}</td>
                                            <td className="px-6 py-4 border-b border-gray-200 whitespace-nowrap text-center"></td>
                                        </tr>
                                    ))}
                                    </tbody>
                                )}
                            </table>
                        </div>
                    </div>
                    {/* Пагинация */}
                    {ordersData?.orders && ordersData.orders.length > 0 && (
                        <div className="flex justify-between items-center py-4 bg-inherit rounded-md">
                            <div className="text-sm text-gray-700">
                                Показано {(ordersData.pagination.currentPage - 1) * ordersData.pagination.pageSize + 1} - {Math.min(ordersData.pagination.currentPage * ordersData.pagination.pageSize, ordersData.pagination.total)} из {ordersData.pagination.total} заказов
                            </div>
                            <div className="flex rounded">
                                {renderPaginationItems()}
                            </div>
                        </div>
                    )}
                </div>
                {!ordersData?.orders && (
                    <div className="flex justify-center items-center h-full">
                        Ничего не найдено
                    </div>
                )}
            </>
        </>
    );
};

export default Warehouse;