import React, { useState } from "react";
import { SearchIcon } from "lucide-react";

export const SearchWithDropdown = ({ onSearch, placeholder }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState("All categories");
    const [searchText, setSearchText] = useState("");

    const categories = ['ИИН', 'Трек-номер'];

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        setIsOpen(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (onSearch) {
            onSearch({ category: selectedCategory, query: searchText });
        }
    };

    return (
        <form onSubmit={handleSubmit} className="max-w-lg mx-auto">
            <div className="flex">
                <button
                    id="dropdown-button"
                    onClick={() => setIsOpen(!isOpen)}
                    className="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100"
                    type="button"
                >
                    {selectedCategory}
                    <svg className="w-2.5 h-2.5 ms-2.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1l4 4 4-4"/>
                    </svg>
                </button>

                {isOpen && (
                    <div className="absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44">
                        <ul className="py-2 text-sm text-gray-700">
                            {categories.map((category) => (
                                <li key={category}>
                                    <button
                                        type="button"
                                        onClick={() => handleCategoryClick(category)}
                                        className="inline-flex w-full px-4 py-2 hover:bg-gray-100"
                                    >
                                        {category}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                <div className="relative w-full">
                    <input
                        type="search"
                        id="search-dropdown"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-e-lg border-s-gray-50 border-s-2 border border-gray-300 focus:outline-none  focus:ring-inset
                            focus:ring-blue-500 sm:text-sm sm:leading-6"
                        placeholder={placeholder}
                        required
                    />
                    <button type="submit" className="absolute top-0 end-0 p-2.5 text-sm font-medium h-full text-white bg-blue-700 rounded-e-lg border border-blue-700 hover:bg-blue-800">
                        <SearchIcon className="w-4 h-4" />
                        <span className="sr-only">Search</span>
                    </button>
                </div>
            </div>
        </form>
    );
};

export default SearchWithDropdown

