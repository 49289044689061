import axios from 'axios';
import {tokenStorage} from "./storage";

const apiClient = axios.create({
    baseURL: 'https://api.k9cargo.kz/api/v1',
    // baseURL: 'http://localhost:8080/api/v1',
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
    timeout: 10000,
});

apiClient.interceptors.request.use(
    async config => {
        // await new Promise(resolve => setTimeout(resolve, 3000))
        const token = tokenStorage.get();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

function handleSuccess(response) {
    //set timeout for test

    if (response.data.entity) {
        return response.data.entity;
    }
    //return no content
    return response.data;
}

function handleSuccessWithHeaders(response) {
    console.log(response.headers)
    return {
        entity: response.data.entity,
        headers: response.headers
    };
}

function handleError(error) {
    let errorMessage;

    if (error.response) {
        errorMessage = error.response.data.message || 'Ошибка сервера';
    } else if (error.request) {
        errorMessage = 'Нет ответа от сервера';
    } else {
        errorMessage = error.message || 'Произошла ошибка при отправке запроса';
    }

    throw new Error(errorMessage);
}


export const api = {
    login: async (username, password) => {
        try {
            const response = await apiClient.post('/employees/login', {username, password});
            return handleSuccess(response);
        } catch (error) {
           return handleError(error);
        }
    },
    me: async() => {
        try {
            const response = await apiClient.get('/employees/me');
            return handleSuccess(response);
        } catch (error) {
            return handleError(error);
        }
    },
    getCellByBarcode: async (pickupID, barcode) => {
        try {
            const response = await apiClient.get(`/pickuppoints/cell/${pickupID}/barcode/${barcode}`);
            return handleSuccess(response);
        } catch (error) {
            return handleError(error);
        }
    },
    getOrderForAcceptance: async (order_track_num, pup_id, cell_id) => {
        try {
            const response = await apiClient.post(`/orders/to_accept`, {order_track_num, pup_id, cell_id});
            return handleSuccess(response);
        } catch (error) {
            return handleError(error);
        }
    },
    acceptOrders: async (ids, cellID) => {
        try {
            const response = await apiClient.patch(`/orders/accept`, {order_ids: ids, cell_id: cellID});
            return handleSuccess(response);
        } catch (error) {
            return handleError(error);
        }
    },
    getPupOrders: async(params) => {
        try {
            const response = await apiClient.get(`/orders/point/list?${params}`);
            return handleSuccessWithHeaders(response);
        } catch (error) {
            return handleError(error);
        }
    },
    searchUser: async(params, options = {}) => {
        try {
            const response = await apiClient.get(`/users/search?${params}`, options);
            return handleSuccess(response);
        } catch (error) {
            return handleError(error);
        }
    },
    issuance: async (ids) => {
        try {
            const response = await apiClient.patch(`/orders/issuance`, {orders_ids: ids});
            return handleSuccess(response);
        } catch (error) {
            return handleError(error);
        }
    },
}
