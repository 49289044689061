import {api} from "./api";
import {tokenStorage} from "./storage";

export const User = {
    get: async () => {
        let user = localStorage.getItem('user');

        if (user) {
            return JSON.parse(user);
        } else {
            try {
                const fetchedUser = await api.me();
                localStorage.setItem('user', JSON.stringify(fetchedUser));
                return fetchedUser;
            } catch (error) {
                throw new Error('Не удалось получить данные пользователя');
            }
        }
    },
    set: async (user) => {
        localStorage.setItem('user', JSON.stringify(user));
    },
    logout: async () => {
        await tokenStorage.remove();
        await localStorage.removeItem('user');
    },
    isAuthenticated: () => {
        return !!tokenStorage.get();
    }
}