import { Link, useLocation } from "react-router-dom";
import { acceptIcon, issuanceIcon, warehouseIcon, panelIcon } from "./ui/icons";

const SideLinks = () => {
    // Получаем текущий путь
    const location = useLocation();

    const menu = [
        {name: 'Прием', path: '/acceptance', icon: acceptIcon},
        {name: 'Выдача', path: '/issuance', icon: issuanceIcon},
        {name: 'Склад', path: '/warehouse', icon: warehouseIcon},
        {name: 'Панель', path: '/panel', icon: panelIcon},
    ];

    return (
        <aside className="w-16 flex flex-col space-y-10 items-center justify-center relative bg-gray-800 text-white">
            {menu.map((item, index) => {
                // Проверяем, является ли текущий путь активным
                const isActive = location.pathname === item.path;

                return (
                    <Link to={item.path} key={index}>
                        <div
                            className={`h-10 w-10 flex items-center justify-center rounded-lg cursor-pointer hover:text-gray-800 hover:duration-300 hover:ease-linear ${
                                isActive ? 'bg-white' : ''
                            }`}
                        >
                            {item.icon({
                                color: isActive ? '#2563eb' : 'white',
                                width: '30',
                                height: '30'
                            })}
                        </div>
                    </Link>
                );
            })}
        </aside>
    );
};

export default SideLinks;