export const WarningBubble = (props) => {
    return (
        <div
            className="flex items-center p-4 mb-4 text-sm text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50"
            role="alert">
            <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                 fill="currentColor" viewBox="0 0 20 20">
                <path
                    d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
            </svg>
            <span className="sr-only">Info</span>
            <div>
                <span className="font-medium">{props.title}</span> {props.text}
            </div>
        </div>
    )
}

export const SuccessBubble = (props) => {
    return (
        <div
            className="flex items-center p-4 mb-4 text-sm text-green-800 border border-green-300 rounded-lg bg-green-50"
            role="alert">
            <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                 fill="currentColor" viewBox="0 0 20 20">
                <path
                    d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
            </svg>
            <span className="sr-only">Info</span>
            <div>
                <span className="font-medium">{props.title}</span> {props.text}
            </div>
        </div>
    )
}

export const InfoBubble = (props) => {
    return (
            <div className="inline-flex w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-md">
                <div className="flex items-center justify-center w-12 bg-blue-500">
                    <svg
                        className="w-6 h-6 text-white fill-current"
                        viewBox="0 0 40 40"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM21.6667 28.3333H18.3334V25H21.6667V28.3333ZM21.6667 21.6666H18.3334V11.6666H21.6667V21.6666Z"
                    />
                </svg>
            </div>

            <div className="px-4 py-2 -mx-3">
                <div className="mx-3">
                    <span className="font-semibold text-blue-500">{props.title}</span>
                    <p className="text-sm text-gray-600">
                    {props.text}
                    </p>
                </div>
            </div>
        </div>
    )
}

export const ErrorBubble = (props) => {
    return (
        <div className="inline-flex w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-md">
            <div className="flex items-center justify-center w-12 bg-red-500">
                <svg className="w-6 h-6 text-white fill-current" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z"></path>
                </svg>
            </div>
            <div className="px-4 py-2 -mx-3">
                <div className="mx-3"><span className="font-semibold text-red-500">Ошибка!</span><p
                    className="text-sm text-gray-600"> {props.text} </p></div>
            </div>
        </div>
    );
}

export const SuccessAlert = ({message, isVisible}) => {
    if (!isVisible) return null;

    return (
        <div className="absolute top-4 left-1/2 transform -translate-x-1/2">
            <div
                className="inline-flex w-full max-w-sm ml-3 overflow-hidden bg-white rounded-lg shadow-md"
            >
                <div className="flex items-center justify-center w-12 bg-green-500">
                    <svg className="w-6 h-6 text-white fill-current" viewBox="0 0 40 40"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z"></path>
                    </svg>
                </div>

                <div className="px-4 py-2 -mx-3">
                    <div className="mx-3"><span className="font-semibold text-green-500">Готово!</span><p
                        className="text-sm text-gray-600">{message}</p></div>
                </div>
            </div>
        </div>
    )

}

export const ErrorAlert = ({message, isVisible}) => {
    if (!isVisible) return null;

    return (
        <div className="absolute top-4 left-1/2 transform -translate-x-1/2">
            <div
                className="inline-flex w-full max-w-sm ml-3 overflow-hidden bg-white rounded-lg shadow-md"
                >
                <div className="flex items-center justify-center w-12 bg-red-500">
                <svg
                className="w-6 h-6 text-white fill-current"
                viewBox="0 0 40 40"
                xmlns="http://www.w3.org/2000/svg"
                >
                <path
                d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z"
                />
                </svg>
                </div>

                <div className="px-4 py-2 -mx-3">
                <div className="mx-3">
                <span className="font-semibold text-red-500">Ошибка!</span>
                <p className="text-sm text-gray-600">
            {message}
            </p>
        </div>
</div>
</div>
</div>

)
    ;
};