export const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('ru-RU', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    });
}

export const getPaginationHeadersFromResponse = (response) => {
    const headers = response.headers;
    return {
        total: headers['x-total'],
        pageSize: headers['x-page-size'],
        currentPage: headers['x-current-page'],
        totalPages: headers['x-page-count'],
    };
};

export const buildQueryString = (params) => {
    const searchParams = new URLSearchParams();
    Object.keys(params).forEach(key => {
        if (params[key] !== undefined && params[key] !== null) {
            searchParams.append(key, params[key]);
        }
    });
    return searchParams.toString();
}

function multiply(iin, weights) {
    return weights.reduce((sum, weight, index) => sum + parseInt(iin[index], 10) * weight, 0);
}

export const validateIIN=(iin) =>{
    if (!/^\d{12}$/.test(iin)) {
        return false;
    }

    const w1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    const w2 = [3, 4, 5, 6, 7, 8, 9, 10, 11, 1, 2];

    let checkSum = multiply(iin, w1) % 11;

    if (checkSum === 10) {
        checkSum = multiply(iin, w2) % 11;
    }

    return checkSum === parseInt(iin[11], 10);
}

