import LoginPage from "../components/LoginPage";
import {User} from "../service/user";
import {Navigate} from "react-router-dom";

const Login = () => {
    if (User.isAuthenticated()) {
        return <Navigate to='/acceptance' />;
    }

    return (
        <div className="">
            <LoginPage />
        </div>
    );
}

export default Login;