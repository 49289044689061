// App.js
import React from 'react';
import {Route, Routes, BrowserRouter, Navigate} from 'react-router-dom';
import Issuance from './pages/Issuance';
import Acceptance from './pages/Acceptance';
import Warehouse from './pages/Warehouse';
import Layout from './pages/Layout'
import Panel from "./pages/Panel";
import ProtectedRouteLogin from "./mw/mw";
import Login from "./pages/Login";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

const queryClient = new QueryClient();

const App = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <Routes>
                    <Route path="/login" element={<Login />} />

                    <Route path="/" element={<Layout />}>
                        <Route path="acceptance" element={<ProtectedRouteLogin element={Acceptance} />} />
                        <Route path="issuance" element={<ProtectedRouteLogin element={Issuance} />} />
                        <Route path="warehouse" element={<ProtectedRouteLogin element={Warehouse} />} />
                        <Route path="panel" element={<ProtectedRouteLogin element={Panel} />} />

                        <Route path="/" element={<Navigate to="/acceptance" />} />
                        <Route path="*" element={<Navigate to="/acceptance" />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </QueryClientProvider>

    );
};

export default App;