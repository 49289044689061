import {trashBinIcon} from "./icons";
import {formatDate} from "../../service/utils";

const Table = ({ columns, data , onDelete}) => {
    return (
        <div className="flex flex-col mt-6">
            <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full">
                        <thead>
                        <tr>
                            {columns.map((column, index) => (
                                <th key={index} className="px-6 py-3 bg-gray-50 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    {column}
                                </th>
                            ))}
                            <th className="px-6 py-3 bg-gray-50 border-b border-gray-200"/>
                        </tr>
                        </thead>
                        {data.length > 0 && (
                            <tbody className="bg-white text-left text-sm text-gray-800">
                            {data.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    <td className="px-6 py-4 border-b border-gray-200 whitespace-nowrap">{rowIndex+1}</td>
                                    <td className="px-6 py-4 border-b border-gray-200 whitespace-nowrap">{row.name}</td>
                                    <td className="px-6 py-4 border-b border-gray-200 whitespace-nowrap">{formatDate(row.created_at)}</td>
                                    <td className="px-6 py-4 border-b border-gray-200 whitespace-nowrap">{row.tracking_number}</td>
                                    <td className="px-6 py-4 border-b border-gray-200 whitespace-nowrap">{row.weight}</td>
                                    <td className="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                                        <div className="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">
                                            {row.order_status}
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 border-b border-gray-200 whitespace-nowrap text-center">
                                        <button onClick={() => onDelete(rowIndex)}>
                                            {trashBinIcon({color: 'red', width: '24px', height: '24px'})}
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        )}
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Table;