import React from 'react';
import {logoutIcon} from "./ui/icons";
import {User} from "../service/user";
import {useNavigate} from "react-router-dom";

const Header = ({user}) => {
    const navigate = useNavigate();
    if (!user) {
        return (
          <p>Loading...</p>
        );

    }

    const logout = () => {
        User.logout().then(() => {
            navigate('/login');
        });
    }

    return (
        <header className="flex items-center justify-between px-6 py-4 bg-white border-b-4 border-blue-600">
            <div className="relative mx-4 lg:mx-0">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <svg className="w-5 h-5 text-gray-500" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                  </svg>
                </span>

                <input className="w-32 pl-10 pr-4 text-indigo-600 border-gray-200 rounded-md sm:w-64 focus:border-indigo-600 focus:ring focus:ring-opacity-40 focus:ring-indigo-500" type="text" placeholder="Search"/>
            </div>

            <div className="flex gap-3 text-gray-600 flex-nowrap">
                <div className="flex flex-col text-sm">
                    <div className="text-nowrap">{user.pickup_point_info.name}</div>
                    <div className="text-nowrap">{user.pickup_point_info.address}</div>
                </div>

                <div className="flex flex-col text-sm">
                    <div>{user.display_name}</div>
                    <div>{user.username}</div>
                </div>

                <button onClick={logout}>
                    {logoutIcon({color: '#0d6efd', width: 20, height: 20})}
                </button>
            </div>
        </header>
    );
};

export default Header;
