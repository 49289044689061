import React, {useEffect, useState} from "react";
import SideLinks from "../components/SideLinks";
import {User} from "../service/user";
import {Outlet} from "react-router-dom";
import Header from "../components/Header";
import {Toaster} from "react-hot-toast";


const Layout = () => {
    const [worker, setWorker] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Оборачиваем асинхронную функцию внутри обычной функции
        const fetchData = async () => {
            try {
                const userData = await User.get();
                setWorker(userData);
            } catch (error) {
                setError(error.message);
            }
        };

        fetchData().then(r => r);
    }, []);

    if (error) {
        alert(error);
    }

    return (
        <>
            <Toaster />
            <div className="flex h-screen bg-gray-200 font-roboto">
                {SideLinks()}
                <div className="flex-1 flex flex-col overflow-hidden">
                    <Header user={worker}/>
                    <main
                        className="flex-grow px-4 md:flex md:flex-col overflow-y-auto overflow-x-hidden lg:flex-grow gap-2 bg-gray-100">
                        <Outlet/>
                    </main>
                </div>
            </div>
        </>

    )
};

export default Layout;