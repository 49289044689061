import React, { useState, useEffect } from 'react';
import { IconButton} from "../components/ui/buttons";
import {buildQueryString, formatDate, getPaginationHeadersFromResponse, validateIIN} from "../service/utils";
import { WarningBubble } from "../components/ui/Alerts";
import { api } from "../service/api";
import { useDebounce } from 'use-debounce';
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {ScanSearch} from "lucide-react";
import toast from 'react-hot-toast';


const Issuance = () => {
    const [iin, setIin] = useState('');
    const [selectedOrders, setSelectedOrders] = useState(new Set());
    const [queryParams, setQueryParams] = useState({
        page: 1,
        status: '6',
        iin: '',
    });

    const [debouncedIin] = useDebounce(iin, 300);

    const {data: user, isLoading: isUserLoading, error: userError, isError: isUserError} = useQuery({
        queryKey: ['user', debouncedIin],
        queryFn: async () => {
            if (!validateIIN(debouncedIin)) {
                throw new Error('Некорректный ИИН');
            }
            return api.searchUser(buildQueryString({ iin: debouncedIin }));
        },
        enabled: debouncedIin.length === 12,
        retry: false,
        staleTime: 1000 * 60,
    });

    const {
        data: ordersData,
        isLoading: isOrdersLoading,
        error: ordersError,
        isError: isOrdersError
    } = useQuery({
        queryKey: ['orders', queryParams],
        queryFn: () => api.getPupOrders(buildQueryString(queryParams)),
        enabled: !!user,
        select: (response) => ({
            orders: response.entity,
            pagination: getPaginationHeadersFromResponse(response)
        }),
        staleTime: 1000 * 60,
    });

    // Reset selected orders when page changes or new search
    useEffect(() => {
        setSelectedOrders(new Set());
    }, [queryParams.page, debouncedIin]);

    const handlePageChange = (page) => {
        if (page < 1 || page > ordersData?.pagination?.totalPages) return;
        setQueryParams(prev => ({
            ...prev,
            page
        }));
    };

    const handleIinChange = (e) => {
        const value = e.target.value.replace(/\D/g, '').slice(0, 12);
        setIin(value);
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            const allIds = ordersData.orders.map(order => order.id);
            setSelectedOrders(new Set(allIds));
        } else {
            setSelectedOrders(new Set());
        }
    };

    const handleOrderSelect = (orderId) => {
        setSelectedOrders(prev => {
            const newSet = new Set(prev);
            if (newSet.has(orderId)) {
                newSet.delete(orderId);
            } else {
                newSet.add(orderId);
            }
            return newSet;
        });
    };

    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (orderIds) => api.issuance(orderIds),
        onSuccess: () => {
            // Очищаем выбранные заказы после успешной отправки
            setSelectedOrders(new Set());
            // Можно добавить уведомление об успехе
            toast.success('Заказы успешно отправлены');
            // Инвалидируем кеш для обновления списка заказов
            queryClient.invalidateQueries(['orders']);
        },
        onError: (error) => {
            console.log(error.message)
            toast.error(`Ошибка при отправке заказов`);
        }
    });

    const handleSubmitOrders = () => {
        if (selectedOrders.size === 0) {
            return;
        }
        console.log(selectedOrders);
        mutation.mutate(Array.from(selectedOrders.values()));
    };

    const columns = ['', '№', 'Наименование', 'Дата создания', 'Трек-номер', 'Вес в гр.','Объем' ,'Статус', 'ПВЗ', 'Получатель','Номер получателя' ,'Ячейка'];

    const isAnyLoading = isUserLoading || isOrdersLoading;

    const renderPaginationItems = () => {
        const items = [];
        const maxVisiblePages = 5;
        let startPage = Math.max(1, ordersData.pagination.currentPage - Math.floor(maxVisiblePages / 2));
        let endPage = Math.min(ordersData.pagination.totalPages, startPage + maxVisiblePages - 1);

        if (endPage - startPage + 1 < maxVisiblePages) {
            startPage = Math.max(1, endPage - maxVisiblePages + 1);
        }

        // Добавляем кнопку "Предыдущая"
        items.push(
            <button
                key="prev"
                onClick={() => handlePageChange(ordersData.pagination.currentPage - 1)}
                disabled={Number(ordersData.pagination.currentPage) === 1}
                className={`px-3 py-2 leading-tight border border-r-0 border-gray-200 rounded-l
                    ${Number(ordersData.pagination.currentPage) === 1
                    ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                    : 'text-indigo-700 bg-white hover:bg-indigo-500 hover:text-white'}`}
            >
                Предыдущая
            </button>
        );

        for (let i = startPage; i <= endPage; i++) {
            items.push(
                <button
                    key={i}
                    onClick={() => handlePageChange(i)}
                    className={`px-3 py-2 leading-tight border border-r-0 border-gray-200
                        ${ordersData.pagination.currentPage === i
                        ? 'bg-indigo-500 text-white'
                        : 'text-indigo-700 bg-white hover:bg-indigo-500 hover:text-white'}`}
                >
                    {i}
                </button>
            );
        }

        items.push(
            <button
                key="next"
                onClick={() => handlePageChange(Number(ordersData.pagination.currentPage) + 1)}
                disabled={ordersData.pagination.currentPage === ordersData.pagination.totalPages}
                className={`px-3 py-2 leading-tight border border-gray-200 rounded-r
                    ${ordersData.pagination.currentPage === ordersData.pagination.totalPages
                    ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                    : 'text-indigo-700 bg-white hover:bg-indigo-500 hover:text-white'}`}
            >
                Следующая
            </button>
        );

        return items;
    };

    return (
        <>
            <div className="flex justify-between flex-nowrap items-center pt-3 pb-2 mb-3 border-b w-full">
                <h1 className="text-2xl font-semibold w-full md:w-auto">Выдача</h1>
            </div>

            <div className="space-y-4">
                <div className="flex gap-2">
                    <div className="max-w-xs">
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <ScanSearch className="w-6 h-6 text-gray-500"/>
                            </div>
                            <input
                                type="text"
                                name="iin"
                                id="iin"
                                value={iin}
                                onChange={handleIinChange}
                                className={`block w-full rounded-md border-0 py-3 pl-10 pr-20 ring-1 ring-inset 
                ${isAnyLoading ? 'bg-gray-100' : 'bg-white'}
                ${isUserError ? 'ring-red-300' : 'ring-gray-300'}
                placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset 
                focus:ring-blue-500 sm:text-sm sm:leading-6`}
                                placeholder="Введите ИИН"
                                disabled={isAnyLoading}
                                aria-label="ИИН пользователя"
                            />
                        </div>
                    </div>
                    {selectedOrders.size > 0 && (
                        <div className="flex space-x-2 mb-2 md:mb-0">
                            <IconButton
                                onClick={handleSubmitOrders}
                                name={`Выдать посылки`}
                            />
                        </div>
                    )}
                </div>


                {/* Индикаторы загрузки */}
                {isUserLoading && (
                    <div className="text-sm text-gray-500">
                        Поиск пользователя...
                    </div>
                )}
                {isOrdersLoading && (
                    <div className="text-sm text-gray-500">
                        Загрузка заказов...
                    </div>
                )}

                {/* Предупреждения */}
                {(isUserError || isOrdersError) && (
                    <div className="max-w-xs">
                        <WarningBubble
                            title={isUserError ? `Ошибка: ${userError.message}` : `Ошибка: ${ordersError.message}`}
                            text={''}/>
                    </div>
                )}

                {/* Информация о пользователе */}
                {user && (
                    <dl className="max-w-xs border p-2 bg-white rounded-md text-gray-900 divide-y divide-gray-200 text-sm">
                        <div className="flex flex-col pb-3">
                            <dt className="mb-1 text-gray-500">Имя пользователя</dt>
                            <dd className="font-semibold">{user.first_name + ' ' + user.last_name}</dd>
                        </div>
                        <div className="flex flex-col py-3">
                            <dt className="mb-1 text-gray-500">ИИН</dt>
                            <dd className="font-semibold">{user.iin}</dd>
                        </div>
                        <div className="flex flex-col pt-3">
                            <dt className="mb-1 text-gray-500">Номер телефона</dt>
                            <dd className="font-semibold">{user.phone_number}</dd>
                        </div>
                    </dl>

                )}

                {user && ordersData?.orders?.length > 0 && (
                    <div className="flex flex-col mt-6">
                        <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                            <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 sm:rounded-lg">
                                <table className="min-w-full">
                                    <thead>
                                    <tr>
                                        <th className="px-6 py-3 bg-gray-50 border-b border-gray-200 text-left">
                                            <div className="flex items-center justify-start">
                                                <input
                                                    type="checkbox"
                                                    className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                                    checked={ordersData.orders.length > 0 && selectedOrders.size === ordersData.orders.length}
                                                    onChange={(e) => handleSelectAll(e)}
                                                />
                                            </div>
                                        </th>
                                        {columns.slice(1).map((column, index) => (
                                            <th key={index}
                                                className="px-6 py-3 bg-gray-50 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                {column}
                                            </th>
                                        ))}
                                        <th className="px-6 py-3 bg-gray-50 border-b border-gray-200"/>
                                    </tr>
                                    </thead>
                                    <tbody className="bg-white text-left text-sm text-gray-800">
                                    {ordersData.orders.map((row, rowIndex) => (
                                        <tr key={rowIndex}>
                                            <td className="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                                                <div className="flex items-center justify-start">
                                                    <input
                                                        type="checkbox"
                                                        className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                                        checked={selectedOrders.has(row.id)}
                                                        onChange={() => handleOrderSelect(row.id)}
                                                    />
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                                                {(ordersData.pagination.currentPage - 1) * ordersData.pagination.pageSize + rowIndex + 1}
                                            </td>
                                            <td className="px-6 py-4 border-b border-gray-200 whitespace-nowrap">{row.name || '-'}</td>
                                            <td className="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                                                {row.created_at ? formatDate(row.created_at) : '-'}
                                            </td>
                                            <td className="px-6 py-4 border-b border-gray-200 whitespace-nowrap">{row.tracking_number || '-'}</td>
                                            <td className="px-6 py-4 border-b border-gray-200 whitespace-nowrap">{row.weight || '-'}</td>
                                            <td className="px-6 py-4 border-b border-gray-200 whitespace-nowrap">{row.volume || '-'}</td>
                                            <td className="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                                                <div className="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">
                                                    {row.external_status || '-'}
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 border-b border-gray-200 whitespace-nowrap">{row.pickup_point_name || '-'}</td>
                                            <td className="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                                                {(row.customer_last_name || row.customer_first_name)
                                                    ? `${row.customer_last_name} ${row.customer_first_name}`
                                                    : '-'}
                                            </td>
                                            <td className="px-6 py-4 border-b border-gray-200 whitespace-nowrap">{row.customer_phone || '-'}</td>
                                            <td className="px-6 py-4 border-b border-gray-200 whitespace-nowrap">{row.cell_number || '-'}</td>
                                            <td className="px-6 py-4 border-b border-gray-200 whitespace-nowrap text-center"></td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="flex justify-between items-center py-4 bg-inherit rounded-md">
                            <div className="text-sm text-gray-700">
                                Показано {(ordersData.pagination.currentPage - 1) * ordersData.pagination.pageSize + 1} - {Math.min(ordersData.pagination.currentPage * ordersData.pagination.pageSize, ordersData.pagination.total)} из {ordersData.pagination.total} заказов
                            </div>
                            <div className="flex rounded">
                                {renderPaginationItems()}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default Issuance;