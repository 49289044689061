import React from 'react';
import {Button} from "../components/ui/buttons";

const Warehouse = () => {
    return (
        <>
            <div className="flex justify-between flex-nowrap items-center pt-3 pb-2 mb-3 border-b w-full">
                <h1 className="text-2xl font-semibold w-full md:w-auto">Управление ПВЗ</h1>
                <div className="flex mb-2 md:mb-0">
                </div>
            </div>
        </>
    );
};

export default Warehouse;