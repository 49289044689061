import {useCallback, useState} from "react";
import {useNavigate} from "react-router-dom";
import {api} from "../service/api";
import {tokenStorage} from "../service/storage";
import toast, {Toaster} from "react-hot-toast";

export const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const redirectUrl = '/acceptance';


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await api.login(username, password);
            let token = response.token
            await tokenStorage.set(token);
            await navigate(redirectUrl);
        } catch (error) {
            toast.error(error.message)
        }
    }

    return (
        <>
            <Toaster/>
            <div className="flex items-center justify-center min-h-screen bg-gray-100">
                <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
                    <div className="mx-auto max-w-lg">
                        <form action="#" className="mb-0 mt-6 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8">
                            <p className="text-center text-lg font-medium">Войдите в аккаунт</p>
                            <div>
                                <label htmlFor="username" className="sr-only">Username</label>
                                <div className="relative">
                                    <input className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                                           value={username} onChange={e => setUsername(e.target.value)}
                                           placeholder="Enter username"/>
                                </div>
                            </div>

                            <div>
                                <label htmlFor="password" className="sr-only">Password</label>
                                <div className="relative">
                                    <input type="password"
                                           className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                                           value={password} onChange={e => setPassword(e.target.value)}
                                           placeholder="Enter password"/>
                                </div>
                            </div>
                            <button type="submit"
                                    className="block w-full rounded-lg bg-blue-600 px-5 py-3 text-sm font-medium text-white"
                                    onClick={handleSubmit}>
                                Sign in
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            </>

            );
            }

            export default LoginPage;